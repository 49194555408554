import Link from '@components/Link';
import type { ReactNode } from 'react';

type CartItemLinkProps = {
    children: ReactNode;
    className?: string;
    slug?: string | null;
    locale: string;
};

const CartItemLink = ({
    children,
    slug,
    locale,
    ...props
}: CartItemLinkProps) => {
    if (!slug) return <div {...props}>{children}</div>;

    return (
        <Link locale={locale} href={slug} {...props}>
            {children}
        </Link>
    );
};

CartItemLink.displayName = 'CartItemLink';

export default CartItemLink;
