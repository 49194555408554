import CartItemDetails from './CartItemDetails';
import CartItemImage from './CartItemImage';
import CartItemItems from './CartItemItems';
import CartItemName from './CartItemName';
import CartItemPrice from './CartItemPrice';
import CartItemQuantity from './CartItemQuantity';
import CartItemRemove from './CartItemRemove';
import CartItemRoot from './CartItemRoot';
import CartItemStock from './CartItemStock';

const composedCartItem = {
    Root: CartItemRoot,
    Name: CartItemName,
    Price: CartItemPrice,
    Image: CartItemImage,
    Details: CartItemDetails,
    Quantity: CartItemQuantity,
    Remove: CartItemRemove,
    Items: CartItemItems,
    Stock: CartItemStock,
};

export default composedCartItem;
