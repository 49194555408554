import { envConfig } from './env';
import { FEATURE_FLAGS } from './featureFlags';

export function isAdyenCheckoutEnabled({ marketCode }: { marketCode: string }) {
    if (['fi', 'dk'].includes(marketCode)) {
        return false;
    }

    return FEATURE_FLAGS.checkout.adyen;
}

export const GIFTCARD_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_GIFT_CARD_PAYMENT_METHOD_ID,
);

export const KLARNA_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_KLARNA_PAYMENT_METHOD_ID,
);

export const ADYEN_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_ADYEN_PAYMENT_METHOD_ID,
);
