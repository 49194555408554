import { cn } from '@/lib/utils';

import { useCartItemContext } from './CartItem.context';
import CartItemLink from './CartItemLink';

type CartItemNameProps = {
    className?: string;
};

const CartItemName = ({ className }: CartItemNameProps) => {
    const { item, locale } = useCartItemContext();

    if (!item) return null;

    return (
        <div className={cn('w-full self-start overflow-hidden', className)}>
            {item.extras?.brand && (
                <span className="text-xxs uppercase">{item.extras.brand}</span>
            )}
            <CartItemLink
                className="flex flex-col overflow-hidden"
                slug={item.extras?.product_url}
                locale={locale}>
                {item.name && <span className="truncate">{item.name}</span>}
            </CartItemLink>
        </div>
    );
};

CartItemName.displayName = 'CartItemName';

export default CartItemName;
