import { service } from '@server/client';
import type {
    GetGiftCardQuery,
    GetGiftCardQueryVariables,
} from '@server/gql/graphql';
import { getGiftCardQuery } from '@server/queries/cart';

export const getGiftCard = async (variables: GetGiftCardQueryVariables) => {
    try {
        const response = await service<
            GetGiftCardQuery,
            GetGiftCardQueryVariables
        >({
            query: getGiftCardQuery,
            variables,
        });

        const giftCard = response?.getGiftCard;

        if (!giftCard) {
            throw new Error('GIFT_CARD_NOT_FOUND');
        }

        if (giftCard.balance === 0) {
            throw new Error('GIFT_CARD_BALANCE_ZERO');
        }

        return giftCard;
    } catch (error: Error | unknown) {
        if (error instanceof Error) {
            if (error.message.includes('Failed to fetch')) {
                throw new Error('GIFT_CARD_NOT_FOUND');
            }

            throw new Error(error.message);
        }

        throw new Error('GIFT_CARD_UNKNOWN_ERROR');
    }
};
