import type { ReactNode } from 'react';

import { cn } from '@/lib/utils';

type CartitemDetailsProps = {
    children: ReactNode;
    className?: string;
};

const CartItemDetails = ({ children, className }: CartitemDetailsProps) => {
    return (
        <div
            className={cn(
                'col-span-1 col-start-2 grid grid-cols-[1fr,auto] grid-rows-[auto,1fr] gap-1.5 overflow-hidden px-3 py-2',
                className,
            )}>
            {children}
        </div>
    );
};

CartItemDetails.displayName = 'CartItemDetails';

export default CartItemDetails;
