'use client';

import type { ImageLoader } from 'next/image';

import { getImageFormat } from './getImageFormat';

const imageLoader: ImageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality ?? 80}${getImageFormat(src)}`;
};

export default imageLoader;
