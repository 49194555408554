import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { nShiftDeliveryOptionSchema } from './address/Address.utils';
import type {
    Address,
    DeliveryAddress,
    NShiftDeliveryOption,
} from './Checkout.types';

type CheckoutStep = 'address' | 'delivery' | 'payment';

export type CheckoutStore = {
    checkoutStep: CheckoutStep;
    address: Partial<Address>;
    hasLine2Address: boolean;
    setHasLine2Address: (hasLine2Address: boolean) => void;
    deliveryAddress: Partial<DeliveryAddress>;
    hasSeparateDeliveryAddress: boolean;
    deliveryOption?: NShiftDeliveryOption;
    setCheckoutStep: (checkoutStep: CheckoutStep) => void;
    setAddress: (newAddress: Partial<Address>) => Promise<void>;
    setDeliveryAddress: (
        newDeliveryAddress: Partial<DeliveryAddress>,
    ) => Promise<void>;
    setHasSeparateDeliveryAddress: (
        hasSeparateDeliveryAddress: boolean,
    ) => void;
    setDeliveryOption: (deliveryOption: Partial<NShiftDeliveryOption>) => void;
};

export const CHECKOUT_STORAGE_KEY = 'ng_checkout';

export const useCheckoutStore = create<CheckoutStore>()(
    devtools(
        persist(
            (set) => ({
                /**
                 * Set default values for the store
                 */
                checkoutStep: 'address',
                address: {},
                deliveryAddress: {},
                hasSeparateDeliveryAddress: false,
                deliveryOption: undefined,
                hasLine2Address: false,
                /**
                 *
                 * @param checkoutStep - The current checkout step
                 * @returns - void
                 */
                setCheckoutStep: (checkoutStep: CheckoutStep) =>
                    set({ checkoutStep }),
                /**
                 *
                 * @param newAddress - The new address
                 * @returns - void
                 */
                setAddress: (newAddress) => {
                    return Promise.resolve(
                        set((state) => {
                            return {
                                address: {
                                    ...state.address,
                                    ...newAddress,
                                    zipCode: newAddress.zipCode?.trim(),
                                },
                            };
                        }),
                    );
                },
                /**
                 * Set whether the address has a C/O address
                 * @param hasLine2Address - Whether the address has a C/O address
                 * @returns - void
                 */
                setHasLine2Address: (hasLine2Address: boolean) => {
                    set({ hasLine2Address });
                },
                /**
                 *
                 * @param newDeliveryAddress - The new delivery address
                 * @returns - void
                 */
                setDeliveryAddress: (newDeliveryAddress) => {
                    return Promise.resolve(
                        set((state) => {
                            return {
                                deliveryAddress: {
                                    ...state.deliveryAddress,
                                    ...newDeliveryAddress,
                                    deliveryZipCode:
                                        newDeliveryAddress.deliveryZipCode?.trim(),
                                },
                            };
                        }),
                    );
                },
                /**
                 *
                 * @param hasSeparateDeliveryAddress - Whether the customer has a separate delivery address
                 * @returns - void
                 */
                setHasSeparateDeliveryAddress: (
                    hasSeparateDeliveryAddress: boolean,
                ) => {
                    set({ hasSeparateDeliveryAddress });
                },
                /**
                 *
                 * @param deliveryOption - The delivery option selected by the customer in nShift
                 * @returns - void
                 */
                setDeliveryOption: (deliveryOption) => {
                    if (!deliveryOption) {
                        set({ deliveryOption: undefined });
                        return;
                    }

                    const validation =
                        nShiftDeliveryOptionSchema.safeParse(deliveryOption);

                    if (!validation.success) {
                        console.error(
                            'Invalid delivery option data',
                            validation.error,
                        );
                        return;
                    }

                    set(() => ({
                        deliveryOption: validation.data,
                    }));
                },
            }),
            {
                // Persist parts of the store in local storage
                name: CHECKOUT_STORAGE_KEY,
                partialize: (state) => {
                    return Object.fromEntries(
                        Object.entries(state).filter(([key]) => {
                            return [
                                'address',
                                'deliveryAddress',
                                'hasSeparateDeliveryAddress',
                            ].includes(key);
                        }),
                    );
                },
            },
        ),
    ),
);
