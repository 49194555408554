import { cn } from '@/lib/utils';
import { formatPrice } from '@/utils/priceUtils';

import { useCartItemContext } from './CartItem.context';

type CartItemPriceProps = {
    className?: string;
};

const CartItemPrice = ({ className }: CartItemPriceProps) => {
    const { locale, item, hideStockStatus } = useCartItemContext();

    if (!item) return null;

    const isDiscounted = item.priceOriginal > item.price;

    return (
        <div
            className={cn(
                'flex flex-col gap-x-1',
                hideStockStatus === false && 'row-start-2 row-end-3',
                className,
            )}>
            {isDiscounted && <p>{formatPrice(item.price, locale)}</p>}
            <p className={cn('', isDiscounted && 'text-grey-300 line-through')}>
                {formatPrice(item.priceOriginal, locale)}
            </p>
        </div>
    );
};

CartItemPrice.displayName = 'CartItemPrice';

export default CartItemPrice;
