import type { CartItem } from '@server/gql/graphql';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Dictionary } from 'types';

type CartItemContextProps = {
    dictionary: Dictionary;
    locale: string;
    item?: CartItem;
    loading: boolean;
    removeItem: (item: CartItem) => Promise<any>;
    updateItem: (item: { item: CartItem; quantity: number }) => Promise<any>;
    hideStockStatus?: boolean;
};

export const CartItemContext = createContext<CartItemContextProps>({
    dictionary: {},
    locale: '',
    loading: false,
    removeItem: () => Promise.resolve(),
    updateItem: () => Promise.resolve(),
    hideStockStatus: false,
});

export type CartItemProviderProps = CartItemContextProps & {
    children: ReactNode;
    className?: string;
};

export const CartItemProvider = ({
    children,
    dictionary,
    locale,
    item,
    removeItem,
    updateItem,
    loading,
}: CartItemProviderProps) => {
    const hideStockStatus = useMemo(() => {
        return (
            item?.stockStatus?.statusCode === 'DeliveryItem' &&
            item.stockStatus.nextDelivery?.quantity === 0
        );
    }, [item?.stockStatus]);

    const value = useMemo(
        () => ({
            dictionary,
            locale,
            item,
            removeItem,
            updateItem,
            loading,
            hideStockStatus,
        }),
        [
            dictionary,
            locale,
            item,
            removeItem,
            updateItem,
            loading,
            hideStockStatus,
        ],
    );

    return (
        <CartItemContext.Provider value={value}>
            {children}
        </CartItemContext.Provider>
    );
};

export const useCartItemContext = () => {
    const context = useContext(CartItemContext);

    if (!context) {
        throw new Error(
            'useCartItemContext must be used within a CartItemProvider',
        );
    }

    return context;
};
