import { service } from '@server/client';
import type {
    GetCheckoutPaymentsQuery,
    GetCheckoutPaymentsQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutPaymentsQuery } from '@server/queries/checkout';

export const getCheckoutPayments = async (
    args: GetCheckoutPaymentsQueryVariables,
) => {
    try {
        const response = await service<
            GetCheckoutPaymentsQuery,
            GetCheckoutPaymentsQueryVariables
        >({
            query: getCheckoutPaymentsQuery,
            variables: args,
        });
        return response.checkout;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getCheckoutPayments)'),
        );
        return e?.response?.data
            ?.checkout as GetCheckoutPaymentsQuery['checkout'];
    }
};
