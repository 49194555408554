import Link from '@components/Link';
import type { CartItem } from '@server/gql/graphql';
import Image from 'next/image';

import { envConfig } from '@/config/env';
import { cn } from '@/lib/utils';

function LineItem({ item }: { item: CartItem }) {
    return (
        <>
            {item.imageKey && (
                <div className="relative block min-h-8 w-8 flex-shrink-0 bg-grey-100">
                    <Image
                        fill
                        className="h-full w-full overflow-hidden object-contain p-1 mix-blend-multiply"
                        src={`${envConfig.NEXT_PUBLIC_NORCE_IMAGE_CDN}/${item.imageKey}?w=32&h=32&mode=max`}
                        alt={item.name}
                    />
                </div>
            )}
            <span className="truncate">{item.name}</span>
        </>
    );
}

export default function Items({
    items,
    locale,
    className,
}: {
    items?: (CartItem | null)[] | null;
    locale: string;
    className?: string;
}) {
    if (!items?.length) return null;
    return (
        <ul className={cn('flex flex-col gap-2 overflow-hidden', className)}>
            {items
                .filter((item): item is CartItem => Boolean(item))
                .map((item) => (
                    <li
                        key={item.id}
                        className="flex items-center gap-2 overflow-hidden text-sm">
                        {item.extras?.product_url ? (
                            <Link
                                href={item.extras.product_url}
                                locale={locale}>
                                <LineItem item={item} />
                            </Link>
                        ) : (
                            <LineItem item={item} />
                        )}
                    </li>
                ))}
        </ul>
    );
}
