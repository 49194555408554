import Link from '@components/Link';
import Image from 'next/image';
import type { ReactNode } from 'react';

import { envConfig } from '@/config/env';
import { cn } from '@/lib/utils';

type ThumbnailProps = {
    alt: string;
    src: string;
    className?: string;
} & (
    | { slug?: never; locale?: never }
    | { slug?: string | null; locale: string }
);

type ThumbnailLinkProps = {
    className?: string;
    slug?: string | null;
    locale?: string;
    children: ReactNode;
};

function ThumbnailLink({
    slug,
    locale,
    children,
    ...props
}: ThumbnailLinkProps) {
    if (!slug) return <div {...props}>{children}</div>;

    return (
        <Link locale={locale} href={slug} {...props}>
            {children}
        </Link>
    );
}

export default function Thumbnail({
    slug,
    alt,
    src,
    locale,
    className,
}: ThumbnailProps) {
    return (
        <ThumbnailLink
            locale={locale}
            slug={slug}
            className={cn('relative bg-grey-100', className)}>
            <Image
                fill
                className="h-full w-full overflow-hidden object-contain mix-blend-multiply"
                src={`${envConfig.NEXT_PUBLIC_NORCE_IMAGE_CDN}/${src}?w=200&h=200&mode=max`}
                alt={alt}
            />
        </ThumbnailLink>
    );
}
