import { cn } from '@/lib/utils';

import type { CartItemProviderProps } from './CartItem.context';
import { CartItemProvider } from './CartItem.context';

type CartItemRootProps = CartItemProviderProps;

const CartItemRoot = ({ children, className, ...props }: CartItemRootProps) => {
    return (
        <CartItemProvider {...props}>
            <div
                className={cn(
                    'relative grid grid-flow-row-dense grid-cols-[auto,1fr] overflow-hidden border-b border-grey-150 leading-tight max-md:text-sm',
                    className,
                )}>
                {children}
            </div>
        </CartItemProvider>
    );
};

CartItemRoot.displayName = 'CartItemRoot';

export default CartItemRoot;
