import { Quantity } from '@ngg/components';
import { MinusIcon, PlusIcon } from '@ngg/icons';

import { cn } from '@/lib/utils';

import { useCartItemContext } from './CartItem.context';

const CartItemQuantity = ({ className }: { className?: string }) => {
    const { loading, item, dictionary, updateItem } = useCartItemContext();

    if (!item) return null;

    return (
        <div className={cn('flex items-center gap-x-1 md:gap-x-2', className)}>
            {dictionary?.quantity && (
                <span className="text-xxs uppercase">
                    {dictionary.quantity}:
                </span>
            )}
            <Quantity
                dense
                readOnly
                disabled={loading}
                value={item.quantity}
                onIncrease={() =>
                    updateItem({
                        item,
                        quantity: item.quantity + 1,
                    })
                }
                onDecrease={() =>
                    updateItem({
                        item,
                        quantity: item.quantity - 1,
                    })
                }
                DecreaseIcon={<MinusIcon />}
                IncreaseIcon={<PlusIcon />}
                classNames={{
                    wrapper:
                        'border-x-1 h-6 border-grey-150 child:border-grey-150 divide-x h-5 max-w-16',
                    decreaseButton:
                        'flex justify-center items-center basis-1/3 text-xs',
                    increaseButton:
                        'flex justify-center items-center basis-1/3 text-xs',
                    input: 'basis-1/3 text-xs',
                }}
            />
        </div>
    );
};

CartItemQuantity.displayName = 'CartItemQuantity';

export default CartItemQuantity;
