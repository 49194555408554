import Thumbnail from '../CheckoutItem/CheckoutItemImage';
import { useCartItemContext } from './CartItem.context';

const CartItemImage = () => {
    const { locale, item } = useCartItemContext();

    if (!item) return null;

    return (
        <Thumbnail
            locale={locale}
            slug={item.extras?.product_url}
            src={item.imageKey}
            alt={item.name}
            className="col-span-1 col-start-1 min-h-24 w-24 min-w-24 md:min-h-28 md:w-28 md:min-w-28"
        />
    );
};

CartItemImage.displayName = 'CartItemImage';

export default CartItemImage;
