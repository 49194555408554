import Stock from '@app/_components/CheckoutItem/CheckoutItemStock';

import { cn } from '@/lib/utils';
import { getNextDeliveryDate } from '@/utils/cart/getNextDeliveryDate';

import { useCartItemContext } from './CartItem.context';

type CartItemStockProps = {
    className?: string;
};

const CartItemStock = ({ className }: CartItemStockProps) => {
    const { item, dictionary, hideStockStatus, locale } = useCartItemContext();

    if (!item || hideStockStatus) return null;

    return (
        <Stock
            className={cn('', className)}
            stockStatus={item.stockStatus?.statusCode}
            deliveryMin={item.stockStatus?.deliveryTime?.from}
            deliveryMax={item.stockStatus?.deliveryTime?.to}
            deliveryUnit={item.stockStatus?.deliveryTime?.unit}
            dictionary={dictionary}
            locale={locale}
            nextDeliveryDate={getNextDeliveryDate(
                item.stockStatus?.nextDelivery,
            )}
        />
    );
};

CartItemStock.displayName = 'CartItemStock';

export default CartItemStock;
