import Items from '@app/_components/CheckoutItem/CheckoutItemItems';

import { useCartItemContext } from './CartItem.context';

const CartItemItems = () => {
    const { item, locale } = useCartItemContext();

    if (!item) return null;

    return <Items items={item.underlyingItems} locale={locale} />;
};

CartItemItems.displayName = 'CartItemItems';

export default CartItemItems;
