import { Paragraph } from '@components/ui';
import { getStockStatusText } from '@ngg/storefront-utils';
import { useMemo } from 'react';
import type { Dictionary } from 'types/common';

import { cn } from '@/lib/utils';
import { isSoonBackInStock } from '@/utils/product/isSoonBackInStock';
import { shouldShowInStockIcon } from '@/utils/product/shouldShowInStockIcon';

import { StockStatus } from '../StockStatus';

export default function Stock({
    stockStatus,
    deliveryMin,
    deliveryMax,
    deliveryUnit,
    dictionary,
    className,
    locale,
    nextDeliveryDate,
}: {
    stockStatus?: string | null;
    deliveryMin?: string | null;
    deliveryMax?: string | null;
    deliveryUnit?: string | null;
    dictionary: Dictionary;
    className?: string;
    locale?: string;
    nextDeliveryDate?: Date;
}) {
    const { stockStatusText, soonBackInStock, showInStockIcon } =
        useMemo(() => {
            const stockStatusText = getStockStatusText({
                stockStatus: stockStatus ?? '',
                deliveryMin: deliveryMin ?? '',
                deliveryMax: deliveryMax ?? '',
                deliveryUnit: deliveryUnit ?? '',
                dictionary,
                locale,
                nextDeliveryDate,
            });
            const soonBackInStock = isSoonBackInStock(
                stockStatus,
                nextDeliveryDate,
            );
            const showInStockIcon = shouldShowInStockIcon(
                soonBackInStock,
                stockStatus,
            );
            return {
                stockStatusText,
                soonBackInStock,
                showInStockIcon,
            };
        }, [
            stockStatus,
            deliveryMax,
            deliveryMin,
            deliveryUnit,
            nextDeliveryDate,
            dictionary,
            locale,
        ]);

    return (
        <Paragraph
            size="xxsmall"
            transform="uppercase"
            className={cn('flex items-center gap-x-1', className)}>
            <StockStatus
                show={!!showInStockIcon}
                soonBackInStock={soonBackInStock}
            />
            {stockStatusText || 'In stock'}
        </Paragraph>
    );
}
