import type { RefObject } from 'react';
import { createContext } from 'react';
import type { Dictionary } from 'types';

type GiftCardContextProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    cardNumberInputRef: RefObject<HTMLInputElement>;
    dictionary: Dictionary;
};

export const GiftCardContext = createContext<GiftCardContextProps | undefined>(
    undefined,
);
