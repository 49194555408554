import type { CartNextDeliveryTime } from '@server/gql/graphql';

export const getNextDeliveryDate = (
    nextDelivery?: CartNextDeliveryTime | null,
) => {
    if (
        !nextDelivery?.date ||
        nextDelivery?.quantity === undefined ||
        nextDelivery.quantity === 0
    )
        return undefined;
    return new Date(nextDelivery.date);
};
