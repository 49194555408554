import { XIcon } from '@ngg/icons';

import { useCartItemContext } from './CartItem.context';

const CartItemRemove = () => {
    const { item, removeItem } = useCartItemContext();

    if (!item) return null;

    return (
        <button
            type="button"
            className="absolute right-1 top-1 bg-transparent p-2"
            onClick={() => removeItem(item)}>
            <XIcon className="h-4 w-4 text-grey-300" />
        </button>
    );
};

CartItemRemove.displayName = 'CartItemRemove';

export default CartItemRemove;
